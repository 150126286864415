import React from 'react';
import { Button, Card } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { authStatus } from '../actions';

import image from '../images/sunrise_logo.png'
import css from './layout/layout.css'

class LoginForm extends React.Component {
  renderError({error, touched}) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  rederInput = ({ input, label, type, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error': ''}`;
    return(
        <div className={className}>
          <label>{label}</label>
          <input {...input} placeholder={label} autoComplete="off" type={type} />
          {this.renderError(meta)}
        </div>
    )
  }

  onSubmit = async (formValues) => {
    this.props.authStatus({isLoading: true});
    try {
            await Auth.signIn(formValues.email, formValues.password);
               this.props.authStatus({isAuthenticated: true, isLoading: false});
        } catch (e) {
            alert(e.message);
            this.props.authStatus({ isLoading: false });
        }
  }

  render(){
    const className = `ui form error ${this.props.auth.isLoading ? 'loading': ''}`;
    return(
      <Card style={{width: 400, padding: 20, margin: 'auto', marginTop: 50}}>
        <img src={image} className="App-logo" alt="logo" />
        <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={className}>
          <Field name="email" label="Email" component={this.rederInput} type="text"/>
          <Field name="password" label="Password" component={this.rederInput} type="password"/>
          <Button primary
            >Login</Button>
        </form>
      </Card>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.email) {
    errors.email = 'You must enter an email';
  }
  if (!formValues.password) {
    errors.password = 'You must enter a password';
  }
  return errors;
};

const mapStateToProps = state => {
  return { auth: state.auth };
};

LoginForm = connect(
  mapStateToProps,
  { authStatus }
)(LoginForm)

export default reduxForm({
  form: 'logIn',
  validate: validate
})(LoginForm);
