import React, { useState, useEffect } from 'react';
import { Button, Divider, Dropdown } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from "react-redux";
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { authStatus } from '../actions';
import NoAccess from './NoAccess'

import { fetchTrusts } from '../actions';

class SignupForm extends React.Component {
  

  componentDidMount() {
    this.props.fetchTrusts();
  }

  renderError({error, touched}) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderTrustDropdown = ({ input, label, type, meta }) => {
    console.log(this.props.trusts)
    return(
        <div className='field'>
          <label>{label}</label>
          <Dropdown
          selection {...input}
          options={this.props.trusts.map(trust => ({
                    key: trust.trust_id,
                    value: trust.trust_id,
                    text: trust.name,
                  }))}
          value={input.value}
          onChange={(param,data) => input.onChange(data.value)}
          placeholder={label}
          >
          </Dropdown>
        </div>
    )
  }
  
  renderLevelDropdown = ({ input, label, type, meta }) => {
    const levels = [
      {key: 'basic', value: 'basic', text: 'Basic User' },
      {key: 'admin', value: 'admin', text: 'Admin User' },
      {key: 'super_admin', value: 'super_admin', text: 'Super Admin User'},
    ]
    return(
        <div className='field'>
          <label>{label}</label>
          <Dropdown
          selection {...input}
          options={levels.map(level => ({
                    key: level.key,
                    value: level.value,
                    text: level.text,
                  }))}
          value={input.value}
          onChange={(param,data) => input.onChange(data.value)}
          placeholder={label}

          >
          </Dropdown>
        </div>
    )
  }

  rederInput = ({ input, label, type, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error': ''}`;
    return(
        <div className={className}>
          <label>{label}</label>
          <input {...input} placeholder={label} autoComplete="off" type={type} />
          {this.renderError(meta)}
        </div>
    )
  }

  onSubmit = async (formValues) => {
    this.props.authStatus({isLoading: true});
    try {
			await Auth.signUp({
          username: formValues.email,
          password: formValues.password,
          attributes: {
              'email': formValues.email,
              // 'given_name': formValues.first_name,
              // 'family_name': formValues.last_name,
              'custom:trust': formValues.trust,
              'custom:level': formValues.level
            }
          })
      await Auth.signIn(formValues.email, formValues.password);
      this.props.authStatus({isAuthenticated: true, isLoading: false});
		} catch (e) {
			alert(e.message);
			this.props.authStatus({ isLoading: false });
		}
  }

  render(){
    const className = `ui form error ${this.props.auth.isLoading ? 'loading': ''}`;
    //if(this.props.user['custom:level'] !== 'super_admin'){ return  <NoAccess />}
    return(
      <div style={{width: 350, padding: 20, marginLeft: 180}}>
        <h1>Add User s  </h1>
        <Divider />
        <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={className}>
          {/* <Field name="first_name" label="First Name" component={this.rederInput} type="text"/>
          <Field name="last_name" label="Last Name" component={this.rederInput} type="text"/> */}
          <Field name="email" label="Email" component={this.rederInput} type="text"/>
          <Field name="password" label="Password" component={this.rederInput} type="password"/>
          <Field name="trust" label="Trust" component={this.renderTrustDropdown} type="text"/>
          <Field name="level" label="Level" component={this.renderLevelDropdown} type="text"/>
          <Button primary
            >Add User</Button>
        </form>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.email) {
    errors.email = 'You must enter an email';
  }
  if (!formValues.password) {
    errors.password = 'You must enter a password';
  }
  return errors;
};

const mapStateToProps = state => {
  return { auth: state.auth, trusts: state.trusts, user: state.user };
};

SignupForm = connect(
  mapStateToProps,
  { authStatus, fetchTrusts }
)(SignupForm)

export default reduxForm({
  form: 'SignupForm',
  validate: validate
})(SignupForm);
