import React from "react";

import LogIn from "../../components/LoginForm";
import SignupForm from "../../components/SignupForm";

const index = () => {
  return (
    <div>
      <LogIn />
    </div>
  );
};

export default index;
